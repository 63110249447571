<template>
    <b-modal id="modal-invite-ecosystem" title="Connect With Ecosystem">
        This ecosystem isn't on SwitchPitch yet. Enter an email for a contact at the company to send an invite.

        <div class="form mt-3">
            <div>
                <template v-if="typeof errors.email !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                </template>
                <template v-if="typeof errors.url !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid mb-2" v-model="email" placeholder="Enter email"></b-form-input>
            </div>
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canAdd" @click="add">
                <span v-if="!loading">Invite To Connect</span>
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import ecosystems from "@/services/ecosystems.service"
import Vue from 'vue';

export default {
    data() {
        return {
            id: 0,
            email: "",

            errors: {},
            result: "",
            loading: false,
        }
    },

    computed: {
        canAdd() {
            return this.email.length
        }
    },

    methods: {
        open(id) {
            this.id = id
            this.email = ""
            this.errors = {}
            this.$bvModal.show('modal-invite-ecosystem')
        },

        add() {
            this.loading = true
            this.errors = {}
            this.result = ""

            let data = {
                email: this.email,
                id: this.id,
            }

            ecosystems.addConnection(data).then(response => {
                this.loading = false
                this.errors = response.data.errors
                this.result = response.data.result

                if (!Object.keys(this.errors).length) {
                    this.$bvModal.hide('modal-invite-ecosystem');
                    this.$emit('inviteSent')

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data.result
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
